<template>
  <v-layout column>
    <v-flex>
      <ReportTabs></ReportTabs>
      <v-container fluid>
        <transition name="slide-x-transition" mode="out-in">
          <router-view></router-view>
        </transition>
      </v-container>
    </v-flex>
  </v-layout>
</template>
<script>
import ReportTabs from "../components/ReportsTabs";
export default {
  name: "ReportsView",
  components: { ReportTabs }
};
</script>
