<template>
  <v-tabs
    slider-color="primary"
    show-arrows
    background-color="#e6e6e6"
    align-with-title
    height="75"
  >
    <v-tab v-for="(item, index) in reportMenu" :key="index" :to="item.to">
      {{ item.text }}
    </v-tab>
  </v-tabs>
</template>
<script>
import { restrictions } from "../common/RestrictionsCode";
import appSettings from "../configuration.js";
import {
  CONTACT_AGREEMENT,
  CONTACT_QUARANTINE,
  LOGIN_HISTORY,
  OPERATION_HISTORY
} from "../types/reports.types.js";

export default {
  name: "ReportsTabs",
  data() {
    return {
      items: [
        {
          to: { name: CONTACT_AGREEMENT },
          text: this.$t(`reports.${CONTACT_AGREEMENT}`),
          auth: this.$can("hasRestriction", {
            code: restrictions.reportsCreate
          })
        },
        {
          to: { name: LOGIN_HISTORY },
          text: this.$t(`reports.${LOGIN_HISTORY}`),
          auth:
            this.$can("admin") &&
            this.$can("hasRestriction", { code: restrictions.reportsCreate })
        },
        {
          to: { name: OPERATION_HISTORY },
          text: this.$t(`reports.${OPERATION_HISTORY}`),
          auth: this.$can("hasRestriction", {
            code: restrictions.reportsCreate
          })
        },
        {
          to: { name: CONTACT_QUARANTINE },
          text: this.$t(`reports.${CONTACT_QUARANTINE}`),
          auth: this.$can("hasRestriction", {
            code: restrictions.reportsCreate
          })
        }
      ]
    };
  },
  computed: {
    reportMenu() {
      return this.items.filter(
        w => w.auth && appSettings.reports.includes(w.to.name)
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.v-tab {
  letter-spacing: 0;
  max-width: 100%;
  margin-right: 35px;
}
.v-tab--active.v-tab {
  color: #000000 !important;
}
</style>
